<template>
  <div class="Result">
    <div class="Result_box">
      <van-image fit="cover" :src="require('@/assets/img/reply.png')" />
      <h4>提交成功</h4>
      <div class="Result_content">
        <div>
          尊敬的客户，您的信息已提交，我们将安排最匹配的客户代表与您联系。您可以前往服务号中<span
            class="blue"
            @click="goApply"
            >我的申请</span
          >查看申请进度，感谢您对中智薪税的信任
        </div>
        <!-- <p>尊敬的客户，您的信息已提交，我们将安排最匹配的客</p>
        <p>
          户代表与您联系。您可以前往服务号中<span class="blue" @click="goApply"
            >我的申请</span
          >
          查看申
        </p>
        <p>请进度，感谢您对中智薪税的信任</p> -->
      </div>
      <div class="bottom_but">
        <van-button round type="info" @click="goMine">前往个人中心</van-button>
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Icon, Image as VanImage, Button } from "vant";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    copyrightIp,
  },
  methods: {
    goApply() {},
    goMine() {
      this.$router.push("/peopleCenter");
    },
  },
};
</script>
<style lang="scss" scoped>
.Result {
  width: 100%;
  height: 100%;
  background: #fff;
  .Result_box {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 100px;
    ::v-deep .van-image {
      width: 150px;
    }

    h4 {
      font-size: 25px;
      padding: 8px 0;
    }
    .Result_content {
      font-size: 12px;
      width: 100%;
      padding: 5px 20px 0;
      // color: #afacac;
      .blue {
        color: blue;
      }
    }
  }
  .bottom_but {
    padding-top: 28px;
    .van-button {
      width: 55%;
    }
  }
}
</style>
